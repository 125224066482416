/** @jsx jsx */
import { jsx } from "@emotion/core";

type NavLink = { name: string; id: string };

export interface NavigationProps {
	links: NavLink[];
}

const RightNavigation = (props: NavigationProps) => {
	const { links } = props;

	return (
		<div
			css={{
				display: "flex",
				height: "100vh",
				position: "fixed",
				right: 100,
				flexDirection: "column",
				justifyContent: "center",
				"@media (max-width: 1024px)": {
					visibility: "hidden",
				},
			}}
		>
			<nav
				css={{
					fontFamily: "Josefin Sans",
					fontSize: 36,
					color: "#4a4a4a",
					display: "grid",
					gridTemplateRows: "1fr repeat",
					gap: 8,
				}}
			>
				{links.map(link => (
					<a
						css={{
							color: "#666666",
							textAlign: "center",
							paddingTop: 8,
							paddingBottom: 8,
							"&:visited": { color: "#666666" },
							"&:hover": { color: "#000000" },
							"&:focus": { boxShadow: "0px 0px 0px 2px rgba(18,66,237,1)" },
						}}
						key={link.id}
						href={link.id}
					>
						{link.name}
					</a>
				))}
			</nav>
		</div>
	);
};

export default RightNavigation;
